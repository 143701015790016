"use client";
import Link from "next/link";
import Form from "../Form/Form";
import { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import clsx from "clsx";
import { Footer } from "@/sanity.types";
import FormConfirm from "../Form/formConfirm";
import { useTranslation } from "@/app/i18n/client";

const FormContainer = ({ moduleData, lng }: { moduleData: Footer["formSettings"]; lng: string }) => {
	const removeSpaces = (str: string | undefined) => (str ? str.replace(/\s/g, "") : "");

	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref, { amount: 0.5, once: true });

	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const { t } = useTranslation(lng);

	useEffect(() => {
		if (isSuccess || isError) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isSuccess, isError]);

	return (
		<>
			<div
				ref={ref}
				className={clsx(
					isInView ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0",
					"relative isolate col-span-5 col-start-10 flex flex-col px-9 py-8 duration-500 before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:rounded-[1.25rem] before:bg-black-20 before:backdrop-blur-[15px] sm:col-span-full sm:col-start-1 sm:row-start-2 xs:p-5",
				)}
			>
				<p className="mb-14 font-secondary text-base text-white opacity-80 sm:text-h5-xs xs:w-9/12 xs:text-base-s">
					{moduleData?.formTitle}
				</p>
				<div className="mb-12">
					<p className="mb-2 font-secondary text-[0.75rem] text-white opacity-80 sm:text-base xs:text-[0.75rem]">
						{moduleData?.telBox?.title}
					</p>
					<Link prefetch={false} href={`tel:${removeSpaces(moduleData?.telBox?.tel)}`} className="duration-500 hover:opacity-50">
						<p className="text-h4 leading-[110%] text-white">{moduleData?.telBox?.tel}</p>
					</Link>
				</div>
				<Form lng={lng} setIsError={setIsError} setIsSuccess={setIsSuccess} />
			</div>
			{(isSuccess || isError) && (
				<FormConfirm
					content={isSuccess ? t("contact.success") : t("contact.failed")}
					setIsSuccess={setIsSuccess}
					setIsError={setIsError}
					lng={lng}
				/>
			)}
		</>
	);
};

export default FormContainer;
