"use client";

import { useTranslation } from "@/app/i18n/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormProps } from "./types";
import clsx from "clsx";
import BtnMain from "../../buttons/BtnMain/BtnMain";
import { Dispatch, SetStateAction } from "react";

const Form = ({
	lng,
	setIsError,
	setIsSuccess,
}: {
	lng: string;
	setIsError: Dispatch<SetStateAction<boolean>>;
	setIsSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
	const { t } = useTranslation(lng);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormProps>();

	const resetFormFields = () => {
		reset({
			email: "",
			message: "",
		});
	};

	const onSubmit: SubmitHandler<FormProps> = async (data) => {
		const formData = new FormData();
		formData.append("email", data.email);
		formData.append("message", data.message);

		try {
			const response = await fetch("/api/contact", {
				method: "post",
				body: formData,
			});

			if (!response.ok) {
				throw new Error(`response status: ${response.status}`);
			}
			const responseData = await response.json();
			console.log(responseData["message"]);
			resetFormFields();
			setIsSuccess(true);
		} catch (error) {
			console.error(error);
			setIsError(true);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<p className="mb-3 font-secondary text-[0.75rem] text-white opacity-80 sm:text-base xs:text-[0.75rem]">{t("contact.form")}</p>
			<input
				className={clsx(
					errors.email ? "border-[red]" : "border-white-30 focus:border-white-60",
					"mb-2 w-full rounded-[0.25rem] border bg-transparent px-5 py-3 text-white outline-none duration-500 placeholder:text-white-30",
				)}
				placeholder={t("contact.email")}
				type="email"
				{...register("email", { required: t("contact.required") })}
			/>
			{errors.email && <p className="mb-3 text-[0.75rem] text-white-60">{errors.email.message}</p>}
			<textarea
				className={clsx(
					errors.message ? "border-[red]" : "border-white-30 focus:border-white-60",
					"w-full rounded-[0.25rem] border bg-transparent px-5 py-3 text-white outline-none duration-500 placeholder:text-white-30",
				)}
				rows={5}
				placeholder={t("contact.message")}
				{...register("message", { required: t("contact.required") })}
			/>
			{errors.message && <p className="mb-3 text-[0.75rem] text-white-60">{errors.message.message}</p>}
			<p className="font-secondary text-[0.75rem] text-white-30">{t("contact.consent")}</p>
			<BtnMain type="submit" label={t("contact.send")} className="float-end mt-9 xs:w-full" />
		</form>
	);
};

export default Form;
