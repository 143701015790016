"use client";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "@/app/i18n/client";
import BtnMain from "../../../buttons/BtnMain/BtnMain";

const FormConfirm = ({
	content,
	setIsSuccess,
	setIsError,
	lng,
}: {
	content: string;
	setIsSuccess: Dispatch<SetStateAction<boolean>>;
	setIsError: Dispatch<SetStateAction<boolean>>;
	lng: string;
}) => {
	const { t } = useTranslation(lng);

	return (
		<div className="success fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center backdrop-blur-[15px]">
			<div className="relative z-[100] flex w-[30%] flex-col items-center gap-y-10 rounded-[1.25rem] bg-white p-10 sm:w-[60%] xs:w-[80%] xs:px-5 xs:py-6">
				<div className="text-h5 text-center text-black xs:text-base">{content}</div>
				<BtnMain
					black
					label={t("contact.close")}
					onClick={() => {
						setIsSuccess(false);
						setIsError(false);
					}}
				/>
			</div>
			<div className="absolute left-0 top-0 z-40 h-full w-full bg-white-10 opacity-80"></div>
		</div>
	);
};

export default FormConfirm;
