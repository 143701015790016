"use client";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

const NavLink = ({
	title,
	url,
	highlighted,
	lng,
	isActive,
	isOnFooter,
}: {
	title?: string;
	url?: string;
	highlighted?: boolean;
	lng: string;
	isActive: boolean;
	isOnFooter: boolean;
}) => {
	const path = usePathname();

	return (
		<Link
			prefetch={false}
			href={url && url.startsWith("#") ? `${path}${url}` : url?.startsWith("http") ? url : `/${lng}${url}`}
			target={url?.startsWith("http") ? "_blank" : "_self"}
		>
			<div
				className={clsx(
					highlighted
						? "bg-blue text-white hover:bg-white hover:text-blue"
						: isActive && !url?.includes("#")
							? "bg-white hover:text-blue"
							: isOnFooter
								? "text-white hover:bg-white hover:text-blue"
								: "bg-transparent hover:bg-white hover:text-blue",
					"rounded-[3.8rem] px-7 py-2.5 font-secondary text-base duration-500",
				)}
			>
				{title}
			</div>
		</Link>
	);
};

export default NavLink;
