import Link from "next/link";
import { usePathname } from "next/navigation";

const MobileMenuItem = ({
	title,
	url,
	lng,
	caseCategories,
}: {
	title?: string;
	url?: string;
	lng: string;
	caseCategories?: {
		title: string;
		slug: {
			_type: string;
			current: string;
		};
	}[];
}) => {
	const path = usePathname();

	return (
		<>
			<Link prefetch={false} href={url && url.startsWith("#") ? `${path}${url}` : `/${lng}${url}`}>
				<div className="text-h4">{title}</div>
			</Link>
			{url?.endsWith("case-studies") && (
				<div className="mt-4 flex flex-col gap-y-2">
					{caseCategories?.map((category) => (
						<Link prefetch={false} key={category.slug.current} href={`/${lng}/case-studies?cat=${category.slug.current}`}>
							<div className="text-base text-gray-700">{category.title}</div>
						</Link>
					))}
				</div>
			)}
		</>
	);
};

export default MobileMenuItem;
