"use client";
import { languages } from "@/app/i18n/settings";
import clsx from "clsx";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";

const LngDropdown = ({
	lng,
	isOnFooter,
	openUp,
	className,
	translations,
}: {
	lng: string;
	isOnFooter: boolean;
	openUp?: boolean;
	className?: string;
	translations: { slug: { current: string }; translationOf: { slug: { current: string } } }[];
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const path = usePathname();
	const router = useRouter();

	const handleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleLngChange = (language: string) => {
		if (path.includes("/case-studies/")) {
			translations.map((caseStudy) => {
				const { slug, translationOf } = caseStudy;
				if (path.includes(slug.current) && language !== lng) {
					router.push(path.replace(slug.current, translationOf.slug.current).replace(lng, language));
				}
			});
		} else {
			router.push(path.replace(lng, language));
		}
		setIsOpen(false);
	};

	return (
		<div
			className={clsx(
				isOpen ? "bg-white" : "bg-gray-100-70 backdrop-blur-[10px]",
				className && className,
				"relative isolate z-40 flex h-16 items-center rounded-[5rem] px-2 duration-500 hover:bg-white",
			)}
		>
			<div
				className={clsx(
					isOpen ? "border-[#8E8F94]" : isOnFooter ? "border-[#ffffff4D]" : "border-[#8E8F944D]",
					"flex cursor-pointer items-center gap-x-[0.625rem] rounded-[5rem] border p-1 duration-500",
				)}
				onClick={handleOpen}
			>
				<div
					className={clsx(
						isOnFooter ? "bg-white text-black" : "bg-black text-white",
						"flex aspect-square h-10 items-center justify-center rounded-[5rem] text-[0.75rem] duration-500",
					)}
				>
					{lng.toUpperCase()}
				</div>
				<div className="mr-2">
					<div
						className={clsx(
							isOpen ? "-scale-x-100 -scale-y-100 opacity-100" : isOnFooter ? "opacity-100" : "opacity-30",
							"duration-500",
						)}
					>
						<svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3 2.5L10 9.5L17 2.5" stroke={isOnFooter ? (isOpen ? "black" : "white") : "black"} />
						</svg>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					isOpen ? "opacity-100" : "opacity-0",
					openUp ? "bottom-0 pb-16 pt-2" : "top-0 pb-2 pt-16",
					"absolute right-0 -z-20 flex w-full flex-col gap-y-1 overflow-hidden rounded-[1.875rem] bg-white duration-500",
				)}
			>
				{isOpen && (
					<>
						{languages.map((language) => (
							<div
								key={language}
								className="group mx-2 cursor-pointer rounded-[1.875rem] border border-white py-3 duration-300 hover:border-[#8E8F944D]"
								onClick={() => handleLngChange(language)}
							>
								<div className="flex items-center justify-center">
									<div className="text-base text-black duration-300 group-hover:text-blue">{language.toUpperCase()}</div>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
};

export default LngDropdown;
