import clsx from "clsx";
import { MouseEventHandler } from "react";

const Hamburger = ({ isOpen, onClick }: { isOpen: boolean; onClick: MouseEventHandler }) => {
	return (
		<div className={clsx(isOpen && "py-4", "z-20 grid duration-300 sm:scale-125 xs:scale-100")} onClick={onClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="34"
				height="2"
				viewBox="0 0 34 2"
				fill="none"
				className={clsx(isOpen ? "col-start-1 row-start-2 -rotate-45" : "mb-2", "duration-300")}
			>
				<path d="M1 1H33" stroke="black" strokeLinecap="round" />
			</svg>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="34"
				height="2"
				viewBox="0 0 34 2"
				fill="none"
				className={clsx(isOpen ? "col-start-1 row-start-2 hidden" : "", "duration-300")}
			>
				<path d="M1 1H33" stroke="black" strokeLinecap="round" />
			</svg>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="34"
				height="2"
				viewBox="0 0 34 2"
				fill="none"
				className={clsx(isOpen ? "col-start-1 row-start-2 rotate-45" : "mt-2", "duration-300")}
			>
				<path d="M1 1H33" stroke="black" strokeLinecap="round" />
			</svg>
		</div>
	);
};

export default Hamburger;
