import(/* webpackMode: "eager" */ "/vercel/path0/app/(site)/[lng]/_assets/images/bb_footer_bg.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/Footer/FormContainer/FormContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(site)/[lng]/_components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"variable\":\"--font-primary\",\"weight\":[\"500\"],\"subsets\":[\"latin\"]}],\"variableName\":\"manropeMedium\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"variable\":\"--font-primary-light\",\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"manropeRegular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"variable\":\"--font-primary-digits\",\"weight\":\"300\",\"subsets\":[\"latin\"]}],\"variableName\":\"manropeLight\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-secondary\",\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-secondary-semibold\",\"weight\":\"500\",\"subsets\":[\"latin\"]}],\"variableName\":\"interSemi\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-secondary-bold\",\"weight\":\"600\",\"subsets\":[\"latin\"]}],\"variableName\":\"interBold\"}");
