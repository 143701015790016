"use client";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import logo from "@assets/images/logo.svg";
import logo_wh from "@assets/images/logo_wh.svg";
import mobileLogo from "@assets/images/m_logo.svg";
import mobileLogotype from "@assets/images/m_logotype.svg";
import Image from "next/image";
import Link from "next/link";
import NavLink from "./NavLink/NavLink";
import LngDropdown from "./LngDropdown/LngDropdown";
import { usePathname } from "next/navigation";
import { AnimatePresence, motion } from "framer-motion";
import Hamburger from "./Hamburger/Hamburger";
import MobileMenuButton from "./MobileMenuButton/MobileMenuButton";
import MobileMenuItem from "./MobileMenuItem/MobileMenuItem";
import { urlFor } from "@/sanity/lib/image";

import type { Footer, Navbar } from "@/sanity.types";

const Navbar = ({
	lng,
	navbarData,
	socialsData,
	caseCategories,
	translations,
}: {
	lng: string;
	navbarData: Navbar;
	socialsData: { socialMedia: Footer["socialMedia"] };
	caseCategories: { title: string; slug: { current: string; _type: string } }[];
	translations: { slug: { current: string }; translationOf: { slug: { current: string } } }[];
}) => {
	const [isMenuBarTop, setIsMenuBarTop] = useState<boolean>(false);
	const [initialMenuBarTop, setInitialMenuBarTop] = useState<number>(0);
	const [isOnFooter, setIsOnFooter] = useState<boolean>(false);
	const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
	const [isPageTop, setIsPageTop] = useState<boolean>(true);
	const [remSize, setRemSize] = useState<number>(16); // Default value for rem size
	const menuBarRef = useRef<HTMLDivElement>(null);
	const path = usePathname();

	const handleScroll = () => {
		if (!menuBarRef.current) {
			return;
		}
		const scrollY = window.scrollY;
		if (scrollY >= initialMenuBarTop - remSize * 1.5) {
			setIsMenuBarTop(true);
		} else {
			setIsMenuBarTop(false);
		}
	};

	useEffect(() => {
		const getRemSize = () => {
			const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
			setRemSize(remInPixels);
		};
		getRemSize();
		window.addEventListener("resize", getRemSize);
		return () => window.removeEventListener("resize", getRemSize);
	}, []);

	useEffect(() => {
		if (menuBarRef.current) {
			setInitialMenuBarTop(menuBarRef.current?.offsetTop);
		}
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [menuBarRef.current]);

	useEffect(() => {
		const checkNavOnFooter = () => {
			const footer = document.getElementById("footer");
			const footerTop = footer?.getBoundingClientRect().top;
			setIsOnFooter(footerTop !== undefined && Math.floor(footerTop) <= 0);
		};
		checkNavOnFooter();
		window.addEventListener("scroll", checkNavOnFooter);
		return () => window.removeEventListener("scroll", checkNavOnFooter);
	}, [path]);

	useEffect(() => {
		const checkPageTop = () => {
			setIsPageTop(window.scrollY <= 0);
		};
		checkPageTop();
		window.addEventListener("scroll", checkPageTop);
		return () => window.removeEventListener("scroll", checkPageTop);
	}, [path]);

	const isActive = (url: string) => {
		const fullPath = `/${lng}${url}`;
		return path === fullPath || (path === `/${lng}` && url === "/") || (url.startsWith("#") && path === `/${lng}`);
	};

	const handleHamburgerClick = () => {
		setIsHamburgerOpen(!isHamburgerOpen);
	};

	return (
		<>
			{/* Desktop Navigation */}
			<nav className="absolute top-0 flex h-lvh w-full flex-col justify-end sm:hidden">
				<div className="fixed top-6 z-40 flex w-full justify-between">
					<div className="ml-[5.25rem] flex h-16 items-center rounded-[5rem] bg-gray-100-70 p-3 backdrop-blur-[10px]">
						<AnimatePresence>
							<Link prefetch={false} href={`/${lng}`}>
								{isOnFooter ? (
									<motion.div
										key={"logo_1"}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
									>
										<Image
											src={logo_wh.src}
											alt="Blue Bolt Logo"
											height={logo.height}
											width={logo.width}
											priority={true}
											loading="eager"
										/>
									</motion.div>
								) : (
									<motion.div
										key={"logo_2"}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
									>
										<Image
											src={logo.src}
											priority={true}
											loading="eager"
											alt="Blue Bolt Logo"
											height={logo.height}
											width={logo.width}
										/>
									</motion.div>
								)}
							</Link>
						</AnimatePresence>
					</div>

					<LngDropdown lng={lng} isOnFooter={isOnFooter} translations={translations} className="mr-[5.25rem]" />
				</div>
				<div
					ref={menuBarRef}
					className={clsx(
						path == `/${lng}` ? (isMenuBarTop ? "fixed" : "relative") : "fixed",
						"left-1/2 top-6 z-100 mb-10 flex w-fit -translate-x-1/2 justify-center md:-translate-x-[40%]",
					)}
				>
					<div className="flex h-16 items-center gap-x-3 rounded-[5rem] bg-gray-100-70 px-3 backdrop-blur-[10px] md:gap-x-2">
						{navbarData.navItems?.map((item, index) => (
							<NavLink key={index} {...item} lng={lng} isOnFooter={isOnFooter} isActive={isActive(item.url ?? "")} />
						))}
					</div>
				</div>
			</nav>

			{/* Mobile Navigation */}
			<nav
				className={clsx(
					!isPageTop && !isHamburgerOpen && "bg-white-90 backdrop-blur-[10px]",
					"fixed top-0 isolate z-100 hidden w-screen overflow-hidden duration-300 sm:block sm:h-24 xs:h-16",
				)}
			>
				<div className="flex h-full items-center justify-between overflow-hidden sm:mx-grid-s xs:mx-grid-xs">
					<Link prefetch={false} href={`/${lng}`} onClick={() => setIsHamburgerOpen(false)}>
						<div className="relative z-20 flex origin-left items-center sm:scale-125 xs:scale-100">
							<Image
								src={mobileLogo.src}
								alt="Blue Bolt Logo"
								height={mobileLogo.height}
								width={mobileLogo.width}
								priority={true}
								loading="eager"
							/>
							<div className="relative -z-10 overflow-hidden pl-2">
								<div
									className={clsx(
										!isPageTop && !isHamburgerOpen ? "-translate-x-[120%]" : "-translate-x-0",
										"duration-300",
									)}
								>
									<Image
										src={mobileLogotype.src}
										alt="Blue Bolt Logo"
										height={mobileLogotype.height}
										width={mobileLogotype.width}
										priority={true}
										loading="eager"
									/>
								</div>
							</div>
						</div>
					</Link>
					<Hamburger onClick={handleHamburgerClick} isOpen={isHamburgerOpen} />
				</div>
			</nav>

			{/* Mobile menu */}
			<div
				className={clsx(
					isHamburgerOpen ? "left-0" : "left-full",
					"fixed top-0 z-90 flex h-dvh w-screen flex-col justify-end bg-white duration-300",
				)}
			>
				<div className="flex flex-col gap-y-5 px-grid-xs pb-[2.12rem]">
					{navbarData.navItems?.map((item, index) =>
						item.highlighted ? (
							<div key={index} onClick={() => setIsHamburgerOpen(false)}>
								<MobileMenuButton {...item} lng={lng} />
							</div>
						) : (
							<div key={index} onClick={() => setIsHamburgerOpen(false)}>
								<MobileMenuItem lng={lng} {...item} caseCategories={caseCategories} />
							</div>
						),
					)}
				</div>
				<div className="mb-5 flex w-full justify-between px-grid-xs">
					<div className="flex items-center gap-x-8">
						{socialsData?.socialMedia?.map((social: NonNullable<Footer["socialMedia"]>[number]) => (
							<Link prefetch={false} href={social.url} key={social._key} className="duration-500 hover:opacity-50">
								<Image
									src={urlFor(social.icon).url()}
									alt={"Visit our profile"}
									width={24}
									height={24}
									className="brightness-0 saturate-100"
								/>
							</Link>
						))}
					</div>
					<LngDropdown lng={lng} isOnFooter={false} translations={translations} openUp />
				</div>
			</div>
		</>
	);
};

export default Navbar;
