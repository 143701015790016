import Link from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";

const MobileMenuButton = ({ title, url, lng }: { title?: string; url?: string; lng: string }) => {
	const path = usePathname();

	return (
		<Link
			prefetch={false}
			href={url && url.startsWith("#") ? `${path}${url}` : url?.startsWith("http") ? url : `/${lng}${url}`}
			target={url?.startsWith("http") ? "_blank" : "_self"}
		>
			<div
				className={clsx(
					"mt-10 rounded-[3.8rem] bg-blue px-7 py-[1.12rem] text-center font-secondary text-base text-white duration-500",
				)}
			>
				{title}
			</div>
		</Link>
	);
};

export default MobileMenuButton;
